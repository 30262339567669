import React from 'react';
import './Board.css';


export class KeyHolderBoard extends React.Component {       // Se puede hacer dependiente de un props "name" y servir para keyHolder, Pqtt mini y otros tipos de bases alternativas
    render() {
        return (
            <div className='board keyHolderBoard'
                  style={{
                    width: `${this.props.size.width}vmin`,
                    height: `auto`
                    }} >
                <img src={require(`./keyHolder/${this.props.currentMaterials.wood}.png`)} alt='keyHolder wood'  className={`keyHolder keyHolderWood`}/>
                <img src={require(`./keyHolder/${this.props.currentMaterials.PLA}.png`)} alt='keyHolder PLA'  className={`keyHolderPLA`}/>
                <img src={require(`./keyHolder/atrezzo.svg`)} alt='keyHolder atrezzo'  className={`keyHolderAtrezzo`}/>
                {this.props.children}
            </div>
        );
    }
};
export const Database = {
    async saveTable(componentList, materials, pqttType, action, email, username, textToEngrave) {
        const body = {
            componentList: componentList,
            materials: materials,
            pqttType: pqttType,
            email: email,
            username: username,
            action: action,
            textToEngrave: textToEngrave
        }

        const header = { 
            'Content-Type': 'application/json',
        }

        let tableId = await fetch('/db/saveTable', {
                method: 'POST', headers: header, body: JSON.stringify(body)
            })
            .then( response => response.json() )
            .then( jsonResponse => {
                return jsonResponse.tableId;
            });

        return tableId;
    },
    
    async confirmPurchase(tableId) {
        const body = {
            tableId: tableId
        }
        
        await fetch('/db/confirmPurchase', {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body)
        });
    },

    getSavedConfig(tableId){
        return fetch(`/db/${tableId}`)
        .then( response => response.json() )
        .then( jsonResponse => jsonResponse);
    },

    async generateConfigURL(tableId) {
        return fetch(`/encryptId/${tableId}`)
        .then( response => response.json() )
        .then( jsonResponse => jsonResponse);
    },

    async readIdURL(encryptedString) {
        return fetch(`/decryptId/${encryptedString}`)
        .then( response => response.json() )
        .then( jsonResponse => jsonResponse);
    }
};
window.ChatraSetup = {
    colors: {
        buttonText: '#FEFDFA', /* chat button text color */
        buttonBg: '#6298ac'    /* chat button background color */
    },
    buttonStyle: 'tab',
    buttonPosition: window.innerWidth < 1024 ? // width threshold
    'rb' : // chat button position on small screens
    'rb',  // chat button position on big screens
};
(function(d, w, c) {
    w.ChatraID = 'aKjTfSSG2haMAdqhQ';
    var s = d.createElement('script');
    w[c] = w[c] || function() {
        (w[c].q = w[c].q || []).push(arguments);
    };
    s.async = true;
    s.src = 'https://call.chatra.io/chatra.js';
    if (d.head) d.head.appendChild(s);
})(document, window, 'Chatra');
import React from 'react';
import './StepButton.css';

export class StepButton extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onClick(this.props.menu);
    }

    render() {
        return (
            <div className={`stepButton ${this.props.menu}Button`}>
                <img src={require(`./${this.props.img}`)} alt={`${this.props.alt} button`} onClick={this.handleClick}/>
                <span className='buttonText'>{this.props.children}</span>
            </div>
        )
    }
}
import React from 'react';

import "./helpButton.css";

export class HelpButton extends React.Component {
    render() {
        return (
            <a href="#chatraChatExpanded" className="helpButton"><img src={require(`./HelpIcon.svg`)} alt="Help Button"></img></a>
        );
    }
}

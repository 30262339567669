import React from 'react';
import './DragDrop.css';
import { DragDropContainer } from './DragDropContainer';
// import { ReactComponent as Svg } from '../CompList/PocketSPqtt.svg';


export class DragPlacedElement extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnDragStart = this.handleOnDragStart.bind(this);
        this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    handleDrop(e) {
        this.props.onElementDrop();
    }

    handleOnDragStart() {
        this.props.onElementDragStart();
    }

    handleOnDragEnd() {
        this.props.onElementDragEnd();
    }

    render() {
        return (
            <DragDropContainer 
                dragClone={false}
                dragData={this.props.dragData} 
                onDrop={this.handleDrop}
                onDragStart={this.handleOnDragStart} 
                onDragEnd={this.handleOnDragEnd} 
                customDragElement={null}
                >
                {this.props.children}
            </DragDropContainer>
        );
    }
}
import React from 'react';
import './StepMenu.css';

export class StepMenu extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    handleClick() {
        this.props.onClick(this.props.menu);
    }

    // Needed for outside click detection
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
          this.handleClick();
        }
    }

    render() {
        // Add listener for outside clicks/touches, removes it when hidden
        !this.props.hidden ? document.addEventListener('mousedown', this.handleClickOutside) : document.removeEventListener('mousedown', this.handleClickOutside);
        !this.props.hidden ? document.addEventListener('touchstart', this.handleClickOutside) : document.removeEventListener('touchstart', this.handleClickOutside);

        return (
        <div className={`stepMenu ${this.props.menu} ${this.props.hidden ? 'hideMenu' : ''}`} ref={this.setWrapperRef}>
            {this.props.children}
        </div>
        );
    }
}
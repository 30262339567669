import './form.css';

import React, { useState, useEffect } from "react";

import TagManager from 'react-gtm-module'

import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";


export function CheckoutForm(props) {

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [coupon, setCoupon] = useState('');
  const [price, setPrice] = useState(props.price);
  const [pqttType, setPqttType] = useState(props.pqttType);
  const [appliedCoupon, setAppliedCoupon] = useState('');
  const [couponError, setCouponError] = useState(false);
  const [savedTable, setSavedTable] = useState({});
  const [successDetails, setSuccessDetails] = useState({});

  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Noto Sans, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const goBack = (e) => {
    e.preventDefault();
    props.toggleStepMenu("PurchasePopup");
    props.toggleStepMenu("EngravingPopup");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();

    setProcessing(true);

    // Guardamos la configuración y la pasamos al State
    const savedTableTemp = await props.saveToDatabase('buyIntent', email, name);
    setSavedTable(savedTableTemp);
    // console.log(savedTableTemp);

    const shipping = {
      "address": {
        "city": city,
        "country": country,
        "line1": address1,
        "line2": address2,
        "postal_code": postalCode,
        // "state": null
      },
      "name": name + ' ' + surname,
      "phone": phone,
    };

    await fetch("/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: `Periquette ${savedTableTemp.encryptedId} ${pqttType}`,
        metadata: {
          pqttType: pqttType,
          coupon: coupon,
          name: name,
          surname: surname,
          phone: phone,
          email: email,
          ...shipping.address,
        }
      })
    })
    .then(res => {
      return res.json();
    })
    .then(async data => {
      const payload = await stripe.confirmCardPayment(data.clientSecret, {
        receipt_email: email,
        shipping: shipping, 
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            ...shipping,
            email: email
          }
        }
      });
      if (payload.error) {
        setError(`El pago ha fallado. ${payload.error.message}`);
        setProcessing(false);
      } else {
        setSuccessDetails(payload.paymentIntent);
        // console.log(payload.paymentIntent);


        // Enviamos mail de confirmación
        fetch('/email/purchasedConfig', {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ 
              lang: props.text.lang,
              pqttType: pqttType,
              savedTable: savedTableTemp,
              shipping: shipping })
        });

        // Pasamos los datos de la compra a GTagManager
        TagManager.dataLayer({
          dataLayer: { purchaseDetails: {
            orderId: savedTableTemp.encryptedId,
            orderAmount: price,
            pqttType: pqttType,
          }
          }
        });

        props.confirmPurchase(savedTableTemp.tableId);
        setError(null);
        setProcessing(false);
        setSucceeded(true);
      }
    });
  };

  const readCoupon = async () => {
    const couponCode = window.location.href.match(/coupon=([^&]*)/);

    if(couponCode) {
      setCoupon(couponCode[1]);
      fetchCoupon(couponCode[1]);
    }
  }

  const handleCoupon = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    if (coupon.length === 0) {
      return;
    };

    fetchCoupon();
  };

  const fetchCoupon = async (couponCode=coupon) => {
    // console.log('fetching coupon');
    try {
      await fetch("/retrieve-coupon", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ couponCode: couponCode, pqttType: pqttType })
      })
      .then(res => {
        return res.json();
      }).then( data => {
        // console.log(data);
        if (data.response/100 === props.price) {
          setPrice(props.price);
          setCouponError(true);
          setAppliedCoupon(null);
        } else {
          setPrice(data.response/100);
          setAppliedCoupon(couponCode);
        }
      });
    } catch(e) {
      setPrice(props.price);
      setCouponError(true);
      setAppliedCoupon(null);
    }
  } 

  useEffect( readCoupon , []); // Leer cupón de la barra de direcciones. OJITO Esta mierda lanza warning en Firefox Dev, pero una vez built el warning se va, así que ignorar

  useEffect(() => { setPrice(props.price) }, [props.price]); // Actualizar el precio si cambiamos el props (cambiando el Pqtt type)

  useEffect(() => { setPqttType(props.pqttType) }, [props.pqttType]); // Actualizar el type si cambiamos el props (cambiando el Pqtt type)

  return (
    <div>
      
      <form id="payment-form" onSubmit={handleSubmit}>
        <span className="formTitle">
          <a className="formBack" onClick={goBack} href="#back">&#60; {props.text.back}</a>
          <p>{props.text.title}</p>
        </span>

        <p className="subsection">{props.text.contactDetails}</p>
        <input    type="text"   autoComplete="first-name"      required    value={name}        onChange={(e) => setName(e.target.value)}       placeholder={props.text.name}      className="thirdForm"/>
        <input    type="text"   autoComplete="family-name"     required    value={surname}     onChange={(e) => setSurname(e.target.value)}    placeholder={props.text.surname}   className="twoThirdForm"/>
        <input    type="tel"    autoComplete="tel"             required    value={phone}       onChange={(e) => setPhone(e.target.value)}      placeholder={props.text.phone}     className="thirdForm"/>
        <input    type="email"  autoComplete="email"           required    value={email}       onChange={(e) => setEmail(e.target.value)}      placeholder={props.text.email}     className="twoThirdForm"/>
        <p className="subsection">{props.text.shippingDetails}</p>
        <input    type="text"   autoComplete="address-line1"   required    value={address1}    onChange={(e) => setAddress1(e.target.value)}   placeholder={props.text.address}/>
        <input    type="text"   autoComplete="address-line2"               value={address2}    onChange={(e) => setAddress2(e.target.value)}   placeholder={props.text.address2}/>
        <input    type="text"   autoComplete="address-level1"  required    value={city}        onChange={(e) => setCity(e.target.value)}       placeholder={props.text.city}        className="thirdForm"/>
        <input    type="text"   autoComplete="postal-code"     required    value={postalCode}  onChange={(e) => setPostalCode(e.target.value)} placeholder={props.text.postalCode}  className="thirdForm"/>
        <select   type="text"                                  required    value={country}     onChange={(e) => setCountry(e.target.value)}                                         className="thirdForm">
          <option value="" disabled defaultValue hidden>{props.text.country}</option>
          <option value="ES">{props.text.countrySpain}</option>
        </select>
        <p><br></br></p>

        <p>{props.text.paymentDetails}</p>
        <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
        <button
          disabled={processing || disabled || succeeded}
          id="purchaseButton" className="payButton"
        >
            {processing ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              props.text.pay
            )}
        </button>

        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}

        {/* Show a success message upon completion
        <p className={succeeded ? "result-message" : "result-message hidden"}>
          Payment succeeded, see the result in your
          <a
            href={`https://dashboard.stripe.com/test/payments`}
          >
            {" "}
            Stripe dashboard.
          </a> Refresh the page to pay again.
        </p> */}

        <h2 className="precioBase precioIntro">{props.text.price}</h2>
        <p className="precioBase descr">{props.text.priceInfo}</p>

        <span className="precioBase">
          {props.price === price ? (
          <h1>
            {props.price}€
          </h1>
        ) : (
          <span>
            <p className="descuento">{appliedCoupon} {props.text.couponApplied}</p>
            <h2 className="originalPrice">{props.price}€</h2>
            <h1>{price}€</h1>
          </span>
        )}
        </span>
    </form>
    <form id="coupon-form" onSubmit={handleCoupon}>
        <input    type="text" value={coupon}        onChange={(e) => {setCoupon(e.target.value); setCouponError(false)}}       placeholder={props.text.couponDiscount}/>
        <button id="couponButton" className="invert">
          {props.text.couponSubmit}
        </button>
        {/* Show any error that happens when processing the payment */}
        {couponError && !appliedCoupon && (
          <div className="card-error" role="alert">
            {props.text.couponError}
          </div>
        )}
    </form>
    {succeeded && (
      <div className="confirmationScreen" id="purchaseConfirmationDiv">
        <div className="popup">
          <a href="https://www.Periquette.com" className='logoFly'>
              <img src={require(`../Logos/Isotipo.svg`)} alt='Periquette logo' />
          </a>
          <div className="confirmationDiv">
            <span>
                <h2>{props.text.successTitle}</h2>
                <h3>{props.text.successId}: {savedTable.encryptedId}</h3>
                <p>{props.text.successThanks} {savedTable.username}. {props.text.successReception}.</p>
                <p>{props.text.successLink} {successDetails.receipt_email}.</p>
                <p>{props.text.successWorking}.</p>
            </span>
            <img src={require(`./SuccesBird.svg`)} alt={`A funny bird celebrating`} className="mascota"/>
          </div>
        </div>
      </div>
    )}
    </div>
  );
}
import './form.css';

import React from 'react';

export class SaveConfigForm extends React.Component {
    constructor(props) {
        super(props);

        this.state= {
            email: '',
            savedTable: {},
            success: false,
            disableButton: true,
            processingButton: false,
            succeededButton: false,
            failure: false,
        }
    }

    handleSubmit = async (ev) => {
        ev.preventDefault();
        this.setState({ failure: false, success: false, processingButton: true })

        let savedTable = undefined;

        try {
            savedTable = await this.props.saveToDatabase('save', this.state.email);

            this.setState({ savedTable: savedTable})
            // console.log(savedTable);
            
            fetch('/email/savedConfig', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
                    savedTable,
                    lang: this.props.text.lang
                })
            }).then(
                this.setState({ success: true, disableButton: true, processingButton: false })
            );
        } catch (e) {
            console.log(e);
            console.log("Fail saving config");
            this.setState({ failure: true, success: false, processingButton: false, disableButton: false })
        }
    }

    handleChange = (e) => {
        this.setState({
            email: (e.target.value),
            disableButton: false
        });
    }

    render() {
        return (
            <form id="saveConfig-form" onSubmit={this.handleSubmit}>
                <h2 className="formTitle">{this.props.text.title}</h2>
                <p>{this.props.text.body}.<br></br>&nbsp;</p>
                <input  type="email" autoComplete="email" required  value={this.state.email} onChange={this.handleChange} placeholder={this.props.text.placeholder}className="twoThirdForm"  />
                <button
                    disabled={ this.state.processingButton || this.state.disableButton || this.state.succeededButton }
                    id="saveConfigButton"
                >
                    { this.state.processingButton ? (
                        <div className="spinner" id="spinner"></div>
                    ) : (
                        this.props.text.button
                    )}
                </button>
                {/* <button id="saveConfigButton" disabled={this.state.disableButton}></button> */}
                { this.state.success && (
                <p className="confirmationMessage" id="saveConfirmationMessage">
                <h2>{this.props.text.successTitle}</h2>
                {this.props.text.successBody} {this.state.savedTable.email}.<br/>
                {this.props.text.successLink1} <a target="_blank" rel="noopener noreferrer" href={this.state.savedTable.configURL}>{this.props.text.successLink2}</a>.</p>
                )}
                { this.state.failure && (
                    <p className="errorMessage">
                        {this.props.text.error}.
                    </p>
                )}
            </form>
        );
    }
}
import React from 'react';
import './Grid.css';
import { DropCell } from '../../DragDrop/DropCell';

export class Grid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validPosition: false,
        }

        this.initialStateCoords = [];
        this.fixedState = {};
        this.behindState = {};
        this.compCounter = 0;
        
        this.gridStyle = {
            padding: `${this.props.pqtt.gridPadding.horizontal}% ${this.props.pqtt.gridPadding.vertical}%`,
            width: `${this.props.pqtt.gridSize.width}%`,
            height: `${this.props.pqtt.gridSize.height}%`,
        }

        this.fixCellState = this.fixCellState.bind(this);
        this.activateCellState = this.activateCellState.bind(this);
        this.resetCellState = this.resetCellState.bind(this);
        this.removeCellState = this.removeCellState.bind(this);
        this.generateTable = this.generateTable.bind(this);
    }
    
    fixCellState(cellsObject, compDepth) {
        // console.log('Fix cells running')
        this.setState((State) => {
            // console.log(State);
            if (!State.validPosition) return this.fixedState;

            cellsObject.pickedCells.forEach( (cell) => {
                if (State[`${cell}`][0] === 'active' || State[`${cell}`][0] === 'idle') {
                    State[`${cell}`] = ['picked', compDepth];
                    this.behindState[`${cell}`] = this.fixedState[`${cell}`];
                }
            });
            // cellsObject.marginCells.forEach( (cell) => {
            //     if ( State[`${cell}`][0] !== 'picked' ) {
            //         State[`${cell}`] = ['pickedMargin', compDepth];
            //         if (this.behindState[`${cell}`] && this.behindState[`${cell}`][0] === 'picked') {
            //             this.behindState[`${cell}`] = ['pickedMargin', compDepth];
            //         } else {
            //             this.behindState[`${cell}`] = this.fixedState[`${cell}`]; // ALGO HAY POR AQUí QUE AL COLOCAR UNA PIEZA EN EL MARGEN SE PERSISTE EL ESTADO DE MARGEN FOREVER
            //         }
            //     }
            // });
            
            if (State.validPosition) return State;
        }, () => {        
        this.fixedState = { ...this.state };
        // console.log('Fixed state');
        // console.log(this.fixedState);
        });
    }

    activateCellState(cellsObject, compDepth) {
        // console.log('Activate cells running')
        this.setState((State) => {
            // console.log(State);
            var changes = 0;

            cellsObject.pickedCells.forEach( (cell) => {
                if (this.fixedState[`${cell}`] && this.fixedState[`${cell}`][0] !== 'picked' && this.fixedState[`${cell}`][1] > compDepth) {
                    State[`${cell}`] = ['active', compDepth];
                    changes++;
                }
            });

            // console.log(changes + ' = ' + cellsObject.pickedCells.length);
            State.validPosition = changes === cellsObject.pickedCells.length;
            this.fixedState.validPosition = State.validPosition;
            // console.log(this.fixedState.validPosition);
            if (!this.fixedState.validPosition) return this.fixedState;

            // cellsObject.marginCells.forEach( (cell) => {
            //     console.log(cell + ' is ' + this.fixedState[`${cell}`][0]);
            //     if (this.fixedState[`${cell}`] &&  this.fixedState[`${cell}`][1] > compDepth) {
            //         State[`${cell}`] = ['activeMargin', compDepth];
            //     } else if ( this.fixedState[`${cell}`] && ( this.fixedState[`${cell}`][0] === 'picked' ) && this.fixedState[`${cell}`][1] < compDepth) {
            //         console.log('hi');
            //         // Do nothing
            //     } else {
            //         State.validPosition = false;
            //         this.fixedState.validPosition = false;
            //         return this.fixedState;
            //     };
            // });
            
            if (State.validPosition) return State;
        });
    }

    resetCellState(cellsObject) {
        // console.log('Reset cells running');
        // console.log(cellsObject);
        this.setState( (State) => {
            cellsObject.pickedCells.forEach( (cell) => {
                State[`${cell}`] = this.fixedState[`${cell}`];
            });
            // En el futuro probablemente tengamos que crear indicadores de objecto y guardarlos en gridCell para comparar
            // cellsObject.marginCells.forEach( (cell) => {
            //     State[`${cell}`] = this.fixedState[`${cell}`];
            // });
            return State;
        });
    }

    removeCellState(cellsObject) {
        // console.log('Remove cells running')
        cellsObject.pickedCells.forEach( (cell) => {
            this.fixedState[`${cell}`] = this.behindState[`${cell}`];
        });
        // cellsObject.marginCells.forEach( (cell) => {
        //     if ( this.fixedState[`${cell}`][0] !== 'picked') {
        //         console.log(cell + ' is removed');
        //         this.fixedState[`${cell}`] = this.behindState[`${cell}`];
        //     }
        // });
    }

    // AQUÍ ESTÁ EL PASTEL. TENEMOS QUE GENERAR LA TABLA SOLO UNA VEZ Y SIN PASAR POR EL STATE.
    // A PROBAR: GENERAR PRIMERO INITIAL STATE COORDS, PASARLO AL STATE, Y UNA VEZ TENEMOS EL STATE MONTADO CON TODAS LAS CELDAS, GENERAMOS LA TABLA (como hacerlo desde compdidupdate sin pasar por state?)
    // PERFORMANCE ISSUE: WE'RE RENDERING THIS WHOLE TABLE EVERYTIME A COMPONENT INTERACTS WITH A CELL. SHOULD BE GENERATED ON COMPDIDMOUNT AND CHANGES APPLIED ONLY TO CORRESPONDING CELLS
    generateTable() {
        const  cols= this.props.pqtt.grid.x;
        const  rows= this.props.pqtt.grid.y;
        const  tabledivs= [];        

        const cellWidth= `${100/cols}%`;
        const cellHeight= `${100/rows}%`;
        
        for (let i = rows-1; i >= 0; i--) {
            let rowCells = [];
            for (let z = 0; z < cols; z++) {
                const coords = `${z}_${i}`;
                // Añadimos componente en esta celda si existe en Customizer.state.placedComponents
                let placedComponent = null;
                this.props.placedComponents.forEach( component => {
                    if (component.cellId.x === z && component.cellId.y === i) {
                        placedComponent = this.props.componentList.filter( (configComponent) => {
                              return component.name === configComponent.object;
                        })[0];
// Hay que rellenar las celdas con el placedComponent porque no salen como "picked"
                        placedComponent.imgSize = {
                            width: `${this.props.pqtt.cellSize.width * placedComponent.x}vmin`,
                            height: `${this.props.pqtt.cellSize.height * placedComponent.y}vmin`
                        }
                    };
                });
                this.initialStateCoords.push(coords);
                rowCells.push(<Cell key={coords}
                                    cellId={{x: z, y: i}}
                                    grid={{x: rows, y: cols}}
                                    subdiv={{x: this.props.pqtt.subdiv.x, y: this.props.pqtt.subdiv.y}}
                                    style={{width: cellWidth}}
                                    className={"gridCell " + (this.state[coords] ? `${this.state[coords][0]}Cell` : '')}
                                    fixCells={this.fixCellState}
                                    activateCells={this.activateCellState}
                                    resetCells={this.resetCellState}
                                    removeCells={this.removeCellState}
                                    compToList={this.props.compToList}
                                    compOutOfList={this.props.compOutOfList}
                                    validPosition={this.state.validPosition}
                                    currentMaterials={this.props.currentMaterials}
                                    placedComponent = {placedComponent}
                                    toggleGrid={this.props.toggleGrid}
                                    toggleDragging={this.props.toggleDragging}
                                    />);
            }
            tabledivs.push(<Row key={`row${i}`} style={{height: cellHeight}} className='gridRow'>{rowCells}</Row>);
        }
        
        return tabledivs;
    }

    render() {
        return (
            <div style={this.gridStyle} className={`grid ${this.props.showGrid? 'showGrid' : ''} ${this.props.draggingComp ? 'dragging' : ''}`}>
                {this.generateTable()}
            </div>
        )
    }

    componentDidMount() {
        this.fixedState = {}

        for (const key in this.initialStateCoords) {
            this.fixedState[`${this.initialStateCoords[key]}`] = ['idle', 5];
        }

        this.setState(this.fixedState);
    }
}

class Row extends React.Component {
    render() {
        return <div style={this.props.style} className={this.props.className}>{this.props.children}</div>;
    }
}

class Cell extends React.Component {
    render() {
        return (
            <div style={this.props.style} className={this.props.className}>
                <DropCell 
                    fixCells={this.props.fixCells}
                    activateCells={this.props.activateCells}
                    resetCells={this.props.resetCells}
                    removeCells={this.props.removeCells}
                    compToList={this.props.compToList}
                    compOutOfList={this.props.compOutOfList}
                    cellId={this.props.cellId}
                    grid={this.props.grid}
                    subdiv={this.props.subdiv}
                    validPosition={this.props.validPosition}
                    currentMaterials={this.props.currentMaterials}
                    placedComponent={this.props.placedComponent}
                    toggleGrid={this.props.toggleGrid}
                    toggleDragging={this.props.toggleDragging}
                    />
            </div>
        );
    }
}

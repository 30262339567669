import React from 'react';
import './MaterialList.css';

export class MaterialList extends React.Component {
    render() {
        return (
            <div className='menuList materialList'>
                {this.props.materials.map((material) => {
                           return <MaterialButton 
                                    {...material}
                                    key={`material${material.name}`} 
                                    selected={this.props.currentMaterials[`${material.name}`]}
                                    displayName={this.props.text[`${material.name}`]}
                                    materialChange={this.props.materialChange}
                                    chooseOneText={this.props.text.chooseOne}/>
                    }
                )}
            </div>
        )
    };
}


class MaterialButton extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            showOptions: false,
            currentOption: this.props.selected
        }

        this.toggleOptions = this.toggleOptions.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    toggleOptions() {
        this.setState({showOptions: !this.state.showOptions});
    }

    // Needed for outside click detection
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClick(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.toggleOptions();
        }
    }

    render() {
        // Add listener for outside clicks, removes it when hidden
        this.state.showOptions ? document.addEventListener('mousedown', this.handleClick) : document.removeEventListener('mousedown',  this.handleClick);
        
        return (
            <div className='materialButton' ref={this.setWrapperRef}>
                <div className={`materialImg`} onClick={this.toggleOptions}>
                    {!this.state.showOptions ? 
                        <img className={`materialImg`}  src={require(`./${this.props.selected}.jpg`)} alt={`Botón para cambiar ${this.props.displayName}`} /> :
                        <div className='placeholderContainer'>
                            <img className={`materialImg`}  src={require(`./buttonWhite.jpg`)} alt='' />
                            <span className='placeholderText body'>{this.props.chooseOneText}</span>
                        </div>
                    }
                </div>

                <span className='materialName buttonText'> {this.props.displayName} </span>
                <div className={`materialOptionsMenu ${this.state.showOptions? '' : 'hideMenu'}`}>
                    {this.props.options.map((option) => {
                        return <OptionButton key={option.file} {...this.props} option={option.file} optionName={option.name} closeMenu={this.toggleOptions}/> 
                    })}
                </div>
            </div>
        )
    }
}

class OptionButton extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.materialChange(this.props.name, this.props.option);
        this.props.closeMenu();
    }

    render() {
        return (
            <img className={`materialOption`} key={`${this.props.name} ${this.props.optionName}`} src={require(`./${this.props.option}.jpg`)} onClick={this.handleClick} alt={`Opción de ${this.props.optionName}`} />
        );
    }
}
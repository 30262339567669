import React from 'react';
import ReactDOM from 'react-dom';
import App from './Components/Customizer/Customizer';
import './index.css';
// i18n aka multilanguage support
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
import React from 'react';
import { DragElement } from '../DragDrop/DragElement';
import './Materials.css';
import './Components.css';
import './CompList.css';

export class CompList extends React.Component {
    render() {
        return (
            <div className="menuList componentList" id="componentList">
                {this.props.components.map((component) =>
                    <div style={{position: 'relative'}} key={component.file}>
                        <DragElement 
                            toggleMenu={this.props.toggleMenu} 
                            toggleGrid={this.props.toggleGrid}
                            toggleDragging={this.props.toggleDragging}
                            dragData={component} 
                            cellSize={this.props.cellSize}
                            currentMaterials={this.props.currentMaterials}
                        />
                        <div className="scrollArea"></div>
                    </div>
                )}
            </div>
        );
    }
}


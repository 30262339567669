import './form.css';

import React from 'react';

export class EngravingForm extends React.Component {
    constructor(props) {
        super(props);

        this.state= {
            savedTable: {},
            success: false,
            disableButton: true,
            processingButton: false,
            succeededButton: false,
            failure: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        this.props.toggleStepMenu("EngravingPopup");
        this.props.toggleStepMenu("PurchasePopup");
    }

    handleChange = (e) => {
        
        //Purgar emojis y cosas raras
        var ranges = [
            '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
            '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
            '\ud83d[\ude80-\udeff]'  // U+1F680 to U+1F6FF
          ];
        var str = e.target.value;
        str = str.replace(new RegExp(ranges.join('|'), 'g'), '');

        this.props.engravingTextChange(str)
    }

    toggleEngraving = () => {
        this.props.toggleState("engraving");
    }

    render() {
        return (
            <form id="engraving-form" onSubmit={this.handleSubmit}>
                {/* Prevent implicit submission of the form to not send form on enter (especially for mobile) */}
                {/* <button type="submit" disabled style={{display: 'none'}} aria-hidden="true"></button> */}

                <p className="formTitle">{this.props.text.title}</p>
                <h2 className="subsection">{this.props.text.engravingSection}</h2>

                <button
                    type="button"
                    id="yesEngravingButton"
                    disabled={this.props.engraving}
                    className={`halfForm radialButton ${this.props.engraving ? 'selected' : 'unselected'}`}
                    onClick={this.toggleEngraving}
                >
                    {this.props.text.engravingButton}<span class="free">{this.props.text.free}</span>
                </button>
                <button
                    type="button"
                    id="noEngravingButton"
                    disabled={!this.props.engraving}
                    className={`halfForm radialButton ${this.props.engraving ? 'unselected' : 'selected'}`}
                    onClick={this.toggleEngraving}
                >
                    {this.props.text.noEngravingButton}
                </button>

                <div
                    className="engravingPreview"
                    style={{
                    backgroundImage: `url(${require(`../Board/${this.props.currentMaterials.wood}.jpg`)})`
                    }}
                >
                    <span className="grabado grabadoNombrePreview">
                        {this.props.engraving && this.props.engravingText ? `${this.props.text.engravingPrefix} ${this.props.engravingText}` : `Periquette`}</span>
                </div>

                <div
                    id="engravingOptions"
                    className={this.props.engraving? "" : "disabled"}
                >
                <p>{this.props.text.body}:</p>
                
                <span className="engravingInputBox" onClick={() => document.getElementById("engravingInput").focus()}>
                    {this.props.text.engravingPrefix}&nbsp;
                    <input 
                        type="text" 
                        autoComplete="name"  
                        maxlength="30"
                        id="engravingInput"
                        className="engravingInput"
                        required
                        disabled={!this.props.engraving}
                        value={this.props.engravingText} 
                        onChange={this.handleChange} 
                        placeholder={this.props.text.placeholder}
                    />
                </span>
                </div>


                <button
                    id="proceedToCheckoutButton"
                    class="thirdForm"
                >
                    {this.props.text.button}
                </button>
            </form>
        );
    }
}
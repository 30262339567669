import React from 'react';
import './DragDrop.css';
import { DragDropContainer } from './DragDropContainer';
import { RealComponent } from './RealComponent';

// import { ReactComponent as Svg } from '../CompList/PocketSPqtt.svg';


export class DragElement extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnDrop = this.handleOnDrop.bind(this);
        this.handleOnDragStart = this.handleOnDragStart.bind(this);
        this.handleOnDragEnd = this.handleOnDragEnd.bind(this);

        this.imgSize = {
            width: `${this.props.cellSize.width * this.props.dragData.x}vmin`,
            height: `${this.props.cellSize.height * this.props.dragData.y}vmin`
        }

        this.dragData = {
            ...this.props.dragData,
            imgSize: this.imgSize,
            onDragStart: this.handleOnDragStart,
            onDragEnd: this.handleOnDragEnd,
        }
    }

    handleOnDrop(e, t, x, y){
        this.props.toggleMenu('CompMenu');  // NOT WORKING I DONT KNOW WHY
    }

    handleOnDragStart() {
        this.props.toggleGrid('show');
        this.props.toggleDragging('true');
    }

    handleOnDragEnd() {
        this.props.toggleGrid('hide');
        this.props.toggleDragging('false');
    }

    render() {
        // const svgElem = <Svg style={this.imgSize} preserveAspectRatio="none"/>;
        return (
            <DragDropContainer 
                dragData={this.dragData} 
                currentMaterials={this.props.currentMaterials} 
                imgSize={this.imgSize}
                onDrop={this.handleOnDrop} 
                onDragStart={this.handleOnDragStart} 
                onDragEnd={this.handleOnDragEnd} 
                customDragElement={<RealComponent {...this.dragData} currentMaterials={this.props.currentMaterials} />}>
                    <img src={require(`../CompList/Pics/${this.props.dragData.file}.png`)} className='component' alt={`${this.props.dragData.file}`}/>
                    { this.props.dragData.descr ? 
                        <p className='componentDescr'>{this.props.dragData.descr}</p> 
                        : null }
            </DragDropContainer>
        );
    }
}
import React from 'react';

export class RealComponent extends React.Component {
    render () {
        // console.log('RealComponent created/updated');
        return ( 
            <div className='component' style={this.props.imgSize}>
                { this.props.materials.wood ? 
                    <img src={require(`../CompList/Pics/${this.props.file}_${this.props.currentMaterials.wood}.png`)} alt={`${this.props.file}`} className={`componentImg wood ${this.props.file}Wood`}/>
                : null }
                { this.props.materials.felt ? 
                    <img src={require(`../CompList/Pics/${this.props.file}_${this.props.currentMaterials.felt}.png`)} alt={`${this.props.file}`}  className={`componentImg felt ${this.props.file}Felt`}/>
                : null }
                { this.props.materials.PLA ? 
                    <img src={require(`../CompList/Pics/${this.props.file}_${this.props.currentMaterials.PLA}.png`)} alt={`${this.props.file}`} className={`componentImg PLA ${this.props.file}PLA`}/>
                : null }
                { this.props.atrezzo ? 
                    <img src={require(`../CompList/Pics/${this.props.file}_Atrezzo.svg`)} alt='' className={`atrezzo ${this.props.file}Atrezzo`}/>
                    : null }
            </div>
        );
    }
  }
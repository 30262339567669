import { globalSizes } from './constants';
 
class Pqtt {
   constructor(object) {
      this.price = object.price;

      this.base = object.base;

      this.baseSize = {
         x: object.baseSize.x,
         y: object.baseSize.y
      };

      this.padding = {
         x: object.padding.x,
         y: object.padding.y
      }

      this.cornerRadius = object.cornerRadius;

      this.components = object.components;

      this.backgrounds = object.backgrounds;

      this.materials = object.materials;

      this.subdiv = {
         x: object.subdiv.x,
         y: object.subdiv.y
      };

      this.divisions = {
         x: object.divisions.x,
         y: object.divisions.y
      };

      this.grid = {
         x: object.divisions.x * object.subdiv.x,
         y: object.divisions.y * object.subdiv.y
      };

      this.proportions = {
         // Obtenemos la dimensión menor y la dividimos entre la mayor para sacar una relación 1:x donde 1=gridCssSize 
         horizontal: object.baseSize.x < object.baseSize.y ? object.baseSize.x / object.baseSize.y : 1,
         vertical: object.baseSize.y < object.baseSize.x ? object.baseSize.y / object.baseSize.x : 1   
      };

      this.board = {
         width: globalSizes.gridCssSize * this.proportions.horizontal,
         height: globalSizes.gridCssSize * this.proportions.vertical,
         cornerRadius: object.cornerRadius / Math.max(object.baseSize.x, object.baseSize.y) * globalSizes.gridCssSize
      }
      
      this.gridPadding = {
         horizontal: object.padding.x / object.baseSize.x * 100,
         vertical: object.padding.y / object.baseSize.y * 100
      }

      this.gridSize = {
         width: 100 - (this.gridPadding.horizontal * 2),
         height: 100 - (this.gridPadding.vertical * 2)
      }

      this.cellSize = {
         width: this.board.width * this.gridSize.width / this.divisions.x / 100,
         height: this.board.height * this.gridSize.height / this.divisions.y / 100
      }
   };
};

const Nightstand = {
   price: 50,

   base: "default",

   baseSize: { 
       x: 380,    // Medidas reales en mm
       y: 380
   },
 
   padding:     { 
       x: 14,    // Medidas reales en mm
       y: 14, 
   },
 
   cornerRadius: 15,   // Medidas reales en mm
    
   divisions: {
      x: 4,
      y: 4
   },
 
   subdiv: { 
       x: 2, 
       y: 2
   },
    
   components: [
      { object: 'Gafas', descr: '', 
         x: 1, y: 1, margin: [1,0,1,0], depth: 1, atrezzo: true, file: 'Gafas', materials: { felt: true, PLA: false, wood: false} },
      { object: 'Movil', descr: '', 
         x: 1, y: 1, margin: [2,0,0,0], depth: 1, atrezzo: true, file: 'Movil', materials: { felt: true, PLA: false, wood: false} },
      { object: 'Cajon 2x', descr: '',   
         x: 2, y: 1, margin: [1,0,0,0], depth: 2, atrezzo: false, file: 'Cajon2x', materials: { felt: true, PLA: true, wood: false} },
      { object: 'Cajon 3x', descr: '',
         x: 3, y: 1, margin: [1,0,0,0], depth: 2, atrezzo: false, file: 'Cajon3x', materials: { felt: true, PLA: true, wood: false} },
      { object: 'Estante 2x S', descr: '',
         x: 2, y: 1, margin: [1,0,0,0], depth: 3, atrezzo: false, file: 'Estante2xS', materials: {felt: false, PLA: true, wood: true} },
      { object: 'Estante 2x L', descr: '',    
         x: 2, y: 1, margin: [1,0,0,0], depth: 3, atrezzo: false, file: 'Estante2xL', materials: {felt: false, PLA: true, wood: true} },
      { object: 'Estante 4x S', descr: '', 
         x: 4, y: 1, margin: [1,0,0,0], depth: 3, atrezzo: false, file: 'Estante4xS', materials: {felt: false, PLA: true, wood: true} },
      { object: 'Estante 4x L', descr: '',
         x: 4, y: 1, margin: [1,0,0,0], depth: 3, atrezzo: false, file: 'Estante4xL', materials: {felt: false, PLA: true, wood: true} }
   ],

   materials: [
      { name: 'wood', displayName: 'madera',  options: [{ name: 'Roble', file: 'woodLight' }, {name: 'Nogal', file: 'woodDark'}, {name: 'Nogal blanco', file: 'woodWhite'}] },
      { name: 'felt', displayName: 'fieltro', options: [{ name: 'Claro', file: 'feltLight' }, {name: 'Dark', file: 'feltDark'}] },
      { name: 'PLA',  displayName: 'detalles',     options: [{ name: 'Blanco', file: 'PLAWhite' }, {name: 'Negro', file: 'PLABlack'}, { name: 'Menta', file: 'PLAMint' },] }, //{name: 'Mostaza', file: 'PLAMustard'}
   ]
};

const EntranceOrganizer = {
   price: 50,

   base: "default",

   baseSize: { 
       x: 380,    // Medidas reales en mm
       y: 380
   },
 
   padding:     { 
       x: 14,    // Medidas reales en mm
       y: 14, 
   },
 
   cornerRadius: 15,   // Medidas reales en mm
    
   divisions: {
      x: 4,
      y: 4
   },
 
   subdiv: { 
       x: 2, 
       y: 2
   },
    
   components: [
      { object: 'Percha', descr: '', 
         x: 1, y: 1, margin: [0,0,1,0], depth: 1, atrezzo: true, file: 'Percha', materials: { felt: false, PLA: true, wood: false} },
      { object: 'Gafas', descr: '', 
         x: 1, y: 1, margin: [1,0,1,0], depth: 1, atrezzo: true, file: 'Gafas', materials: { felt: true, PLA: false, wood: false} },
      { object: 'Movil', descr: '', 
         x: 1, y: 1, margin: [2,0,0,0], depth: 1, atrezzo: true, file: 'Movil', materials: { felt: true, PLA: false, wood: false} },
      { object: 'Cuelgallaves 2x', descr: '', 
         x: 2, y: 1, margin: [0,0,1,0], depth: 1, atrezzo: true, file: 'Cuelgallaves2x', materials: { felt: false, PLA: true, wood: true} },
      { object: 'Cuelgallaves 4x', descr: '', 
         x: 4, y: 1, margin: [0,0,1,0], depth: 1, atrezzo: true, file: 'Cuelgallaves4x', materials: { felt: false, PLA: true, wood: true} },
      { object: 'Cajon 2x', descr: '',   
         x: 2, y: 1, margin: [1,0,0,0], depth: 2, atrezzo: false, file: 'Cajon2x', materials: { felt: true, PLA: true, wood: false} },
      { object: 'Cajon 3x', descr: '',
         x: 3, y: 1, margin: [1,0,0,0], depth: 2, atrezzo: false, file: 'Cajon3x', materials: { felt: true, PLA: true, wood: false} },
      { object: 'Estante 2x S', descr: '',
         x: 2, y: 1, margin: [1,0,0,0], depth: 3, atrezzo: false, file: 'Estante2xS', materials: {felt: false, PLA: true, wood: true} },
      { object: 'Estante 2x L', descr: '',    
         x: 2, y: 1, margin: [1,0,0,0], depth: 3, atrezzo: false, file: 'Estante2xL', materials: {felt: false, PLA: true, wood: true} },
      { object: 'Estante 4x S', descr: '', 
         x: 4, y: 1, margin: [1,0,0,0], depth: 3, atrezzo: false, file: 'Estante4xS', materials: {felt: false, PLA: true, wood: true} },
      { object: 'Estante 4x L', descr: '',
         x: 4, y: 1, margin: [1,0,0,0], depth: 3, atrezzo: false, file: 'Estante4xL', materials: {felt: false, PLA: true, wood: true} }
   ],

   materials: [
      { name: 'wood', displayName: 'madera',  options: [{ name: 'Roble', file: 'woodLight' }, {name: 'Nogal', file: 'woodDark'}, {name: 'Nogal blanco', file: 'woodWhite'}] },
      { name: 'felt', displayName: 'fieltro', options: [{ name: 'Claro', file: 'feltLight' }, {name: 'Dark', file: 'feltDark'}] },
      { name: 'PLA',  displayName: 'detalles',     options: [{ name: 'Blanco', file: 'PLAWhite' }, {name: 'Negro', file: 'PLABlack'}, { name: 'Menta', file: 'PLAMint' },] }, //{name: 'Mostaza', file: 'PLAMustard'}
   ]
};

const KeyHolder = {
   price: 20,

   base: "keyHolder",

   baseSize: { 
       x: 380,    // Medidas reales en mm
       y: 75
   },
 
   padding:     { 
       x: 30,    // Medidas reales en mm
       y: 30, 
   },
 
   cornerRadius: 15,   // Medidas reales en mm
    
   divisions: {
      x: 4,
      y: 1
   },
 
   subdiv: { 
       x: 1, 
       y: 1
   },
    
   components: [],

   materials: [
      { name: 'wood', displayName: 'madera',  options: [{ name: 'Roble', file: 'woodLight' }, {name: 'Nogal', file: 'woodDark'}, {name: 'Nogal blanco', file: 'woodWhite'}] },
      { name: 'PLA',  displayName: 'detalles',     options: [{ name: 'Blanco', file: 'PLAWhite' }, {name: 'Negro', file: 'PLABlack'}, { name: 'Menta', file: 'PLAMint' },] }, //{name: 'Mostaza', file: 'PLAMustard'}
   ]
};

export const Pqtts = {
    nightstand: new Pqtt(Nightstand),
    entranceOrganizer: new Pqtt(EntranceOrganizer),
    keyHolder: new Pqtt(KeyHolder)
};
import React from 'react';
import './GrabadoLogo.css';

export class GrabadoLogo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validPosition: false,
        }
    }
    
    render() {
        return (
            <div className={`grabadoBlock ` + this.props.pqttType}>
                <span className="grabado grabadoNombre">
                    {this.props.engraving && this.props.engravingText ? `${this.props.text.engravingPrefix} ${this.props.engravingText}` : `Periquette`}
                </span>
                <div className="grabado">           
                    <img src={require(`../../Logos/IsotipoContorno.svg`)} alt='Periquette logo' />
                    <div>{this.props.unitNumber}</div>
                </div>
            </div>

        )
    }

}

import React from 'react';
import { DropTarget } from './DropTarget';
import { DragPlacedElement } from './DragPlacedElement';
import { RealComponent } from './RealComponent';
import './DragDrop.css';

DropTarget.defaultProps = {
  children: null,
  targetKey: 'comp',
  onDragEnter: () => {},
  onDragLeave: () => {},
  onHit: () => () => {},
  dropData: {},
  highlightClassName: null,
  render: null,
};

export class DropCell extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        targetKey: 'comp',
        dragData: null,
      }

      this.occupiedCells = [];
      this.hitCells = [];

      this.handleHit = this.handleHit.bind(this);
      this.handleDrop = this.handleDrop.bind(this);
      this.handleDragStart = this.handleDragStart.bind(this);
      this.handleDragEnd = this.handleDragEnd.bind(this);
      this.handleDragEnter = this.handleDragEnter.bind(this);
      this.handleDragLeave = this.handleDragLeave.bind(this);
      this.calculatePickedCells = this.calculatePickedCells.bind(this);
    }

    // Función que calcula las celdas que ocupa el elemento a partir de su tamaño
    calculatePickedCells(sizeX, sizeY, margin) {
      var pickedCellsArray = []
      var marginCellsArray = [];

      const sizeXSubdiv = sizeX * this.props.subdiv.x;
      const sizeYSubdiv = sizeY * this.props.subdiv.y;

      if (this.props.cellId.x + sizeXSubdiv <= this.props.grid.x && this.props.cellId.y - sizeYSubdiv >= -1) { //Checking if the component is fully inside the grid, otherwise don't waste time calculating;
        for (var x = 0; x < sizeXSubdiv; x++) {
          for (var y = 0; y < sizeYSubdiv; y++ ) {
            pickedCellsArray.push(`${this.props.cellId.x + x}_${this.props.cellId.y - y}`);
          }
        }
        // Margins
        // if (margin[0] > 0) {
        //   for ( x = 0; x < sizeXSubdiv; x++) {
        //     for ( y = 1 ; y <= margin[0] * this.props.subdiv.y; y++) {
        //       if (this.props.cellId.y + y < this.props.grid.y) {
        //         marginCellsArray.push(`${this.props.cellId.x + x}_${this.props.cellId.y + y}`);
        //         // console.log(`Margin Top: ${this.props.cellId.x + x}_${this.props.cellId.y - y}`);
        //       };
        //     };
        //   };
        // };
        // if (margin[1] > 0) {
        //   for ( x = 0 + sizeXSubdiv; x < sizeXSubdiv + (margin[1] * this.props.subdiv.x); x++) {
        //     for( y = 0; y < sizeYSubdiv; y++ ) {
        //       if (this.props.cellId.x + x < this.props.grid.x) {
        //         marginCellsArray.push(`${this.props.cellId.x + x}_${this.props.cellId.y - y}`);
        //         // console.log(`Margin Right: ${this.props.cellId.x + x}_${this.props.cellId.y - y}`);
        //       };
        //     };
        //   };
        // }
        // if (margin[2] > 0) {
        //   for ( x = 0; x < sizeXSubdiv; x++) {
        //     for ( y = sizeYSubdiv;  y < sizeYSubdiv + (margin[2] * this.props.subdiv.y); y++) {
        //       if (this.props.cellId.y - y >= 0 ) {
        //         marginCellsArray.push(`${this.props.cellId.x + x}_${this.props.cellId.y - y}`);
        //         // console.log(`Margin Bottom: ${this.props.cellId.x + x}_${this.props.cellId.y - y}`);
        //       };
        //     };
        //   };
        // };
        // if (margin[3] > 0) {
        //   for ( x = 1 ; x <= margin[3] * this.props.subdiv.x; x++) {
        //     for( y = 0; y < sizeYSubdiv; y++ ) {
        //       if (this.props.cellId.x - x >= 0) {
        //         marginCellsArray.push(`${this.props.cellId.x - x}_${this.props.cellId.y - y}`);
        //         // console.log(`Margin Left: ${this.props.cellId.x - x}_${this.props.cellId.y - y}`);
        //       };
        //     };
        //   };
        // };
      };
      
      return { pickedCells: pickedCellsArray, marginCells: marginCellsArray};
    }

    // Esto es para la celda, cuando los elementos draggables interactúan con ella
    handleDragEnter(e){
      this.occupiedCells = this.calculatePickedCells(e.dragData.x, e.dragData.y, e.dragData.margin);
      if (this.occupiedCells.pickedCells && this.occupiedCells.pickedCells.length > 0){
        this.props.activateCells(this.occupiedCells, e.dragData.depth);
      };
    }

    handleHit(e) {
      if (this.props.validPosition && this.occupiedCells.pickedCells && this.occupiedCells.pickedCells.length > 0) {
        this.props.fixCells(this.occupiedCells, e.dragData.depth);
        this.setState({
          dragData: e.dragData,
        }, () => {
          this.props.compToList(this.props.cellId, this.state.dragData.object);
        });
        this.hitCells = this.occupiedCells; // Guardamos estas celdas para cuando nos llevemos el draggable
        this.occupiedCells = {};
      } else {
        // ADD ALERTITA SUTIL DE QUE LA POSICION NO ES VÁLIDA
      }
    }

    handleDragLeave(e){
      // console.log('dragLeave fired');
      if (this.occupiedCells.pickedCells && this.occupiedCells.pickedCells.length > 0) {
        this.props.resetCells(this.occupiedCells);
      }
    }

    // Esto es para el elemento draggable, cuando él cae sobre una celda desde este DropCell borra el contenido de este
    handleDrop() {
      this.setState({
        dragData: ''
      })
    }

    handleDragStart() {
      // console.log('dragStart fired');
      if (this.hitCells.pickedCells && this.hitCells.pickedCells.length > 0) {
        this.props.removeCells(this.hitCells);
        this.props.resetCells(this.hitCells);
      }
      this.props.compOutOfList(this.props.cellId);
      this.props.toggleGrid('show')
      this.props.toggleDragging('true')
    }

    handleDragEnd() {
      this.props.toggleGrid('hide')
      this.props.toggleDragging('false')
    }
  
    componentDidUpdate(previousProps, previousState) {
      if (previousProps.placedComponent !== this.props.placedComponent && this.props.placedComponent !== null) {
        this.setState({dragData: this.props.placedComponent});
      };
    }

    render() {
      return (
        <DropTarget
          targetKey={this.state.targetKey} 
          onHit={this.handleHit}
          onDragEnter={this.handleDragEnter}
          onDragLeave={this.handleDragLeave}
          highlightClassName={this.state.highlightClassName}>
            { this.state.dragData ?  
            <DragPlacedElement 
              dragData={this.state.dragData} 
              onElementDrop={this.handleDrop} 
              onElementDragStart={this.handleDragStart} 
              onElementDragEnd={this.handleDragEnd}
              >
                <RealComponent {...this.state.dragData} currentMaterials={this.props.currentMaterials} /> 
            </DragPlacedElement>
            : null }
        </DropTarget>
      );
    }
  }
import React from 'react';

import "./popups.css";

export class Popup extends React.Component {
    constructor(props) {
        super(props);

        this.closeMenu = this.closeMenu.bind(this);
    }

    closeMenu() {
        this.props.onClick(this.props.menu);
    }

    render() {
        return (
            <div>
                <div className={`${this.props.hidden ? "hidden" : "popup"} ${this.props.className}`}>
                    <div className="closeButton" onClick={this.closeMenu}>x</div>
                    {this.props.children}
                </div>
                <div className={`${this.props.hidden ? "hidden" : "popupBg"}`} onClick={this.closeMenu}></div>
            </div>
        );
    }
}
import React from 'react';
import './Board.css';


export class Board extends React.Component {
    render() {
        return (
            <div className='board'
                 style={{
                    width: `${this.props.size.width}vmin`,
                    height: `${this.props.size.height}vmin`,
                    backgroundImage: `url(${require(`./${this.props.currentMaterials.wood}.jpg`)})`,
                    borderRadius: `${this.props.size.cornerRadius}vmin` }} >
                <img src={require(`./cubretornillos_${this.props.currentMaterials.felt}.png`)} alt='Cubretornillos fieltro'  className={`cubretornillos cubrefieltro`}/>
                <img src={require(`./cubretornillos_${this.props.currentMaterials.PLA}.png`)} alt='Cubretornillos PLA'  className={`cubretornillos cubrePLA`}/>
                {this.props.children}
            </div>
        );
    }
};
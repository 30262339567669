import './Customizer.css';

import React, { Suspense } from 'react';

import { withTranslation } from 'react-i18next';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import TagManager from 'react-gtm-module';

import { Pqtts } from './PqttConstructor';
import { Board } from './Board/Board';
import { KeyHolderBoard } from './Board/KeyHolderBoard';
import { Grid } from './Board/Grid/Grid';
import { GrabadoLogo } from './Board/Grabado/GrabadoLogo';
import { StepButton } from './StepButton/StepButton';
import { StepMenu } from './StepMenu/StepMenu';
import { CompList } from './CompList/CompList';
import { MaterialList } from './MaterialList/MaterialList';
import { DropTarget } from './DragDrop/DropTarget';
import { Popup } from './Popups/Popup';
import { CheckoutForm } from "./Forms/CheckoutForm";
import { EngravingForm } from "./Forms/EngravingForm";
import { SaveConfigForm } from './Forms/SaveConfigForm';
import { HelpButton } from './HelpButton/HelpButton';
import { Database } from '../../Util/Database';
import { AppendScript } from '../../Util/AppendScript';

// TEST KEY. FOR DEV PURPOSES
// const promise = loadStripe('pk_test_2jhw9vMuihzYAqM93dSsYqRL00WtF99Pcp'); alert("Stripe on DEVMODE");
// LIVE KEY. ACTIVATE BEFORE BUILD
const promise = loadStripe('pk_live_ju2nk93B4YWQHWJm8KQntKvn00TlgtWJPG');

class Customizer extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        periquetteType: 'entranceOrganizer',  // Por defecto mostramos el Periquette recibidor que tiene todos los componentes, al cargar detectamos el tipo desde href
        wood: 'woodLight',
        felt: 'feltLight',
        PLA: 'PLABlack',
        placedComponents: [],
        tableId: null,
        engraving: true,
        engravingText: null,
        unitNumber: 'nº',

        draggingComp: false,
        showGrid: false,

        CompMenuHidden: true,
        ColorMenuHidden: true,
        DoneMenuHidden: true,
        PurchasePopupHidden: true,
        EngravingPopupHidden: true,
        SaveConfigPopupHidden: true,
      };

      this.toggleStepMenu = this.toggleStepMenu.bind(this);
      this.toggleState = this.toggleState.bind(this);      
      this.materialChange = this.materialChange.bind(this);
      this.engravingTextChange = this.engravingTextChange.bind(this);
      this.toggleGrid = this.toggleGrid.bind(this);
      this.toggleDragging = this.toggleDragging.bind(this);
      this.compToList = this.compToList.bind(this);
      this.compOutOfList = this.compOutOfList.bind(this);
      this.saveToDatabase = this.saveToDatabase.bind(this);
      this.renderPqttTypeBase = this.renderPqttTypeBase.bind(this);
    }

    toggleState(stateName) {
      this.setState({
        [`${stateName}`]: !this.state[`${stateName}`] 
      })
    }

    toggleStepMenu(menuName) {
      this.setState({
        [`${menuName}Hidden`]: !this.state[`${menuName}Hidden`],
      });
    }

    materialChange(material, newValue) {
      const NewState = {[`${material}`]: newValue}
      this.setState(NewState);
    }

    engravingTextChange(newValue) {
      this.setState({
        engravingText: newValue
      })
    }

    compToList(cellId, compName) {
      this.setState({
        placedComponents: this.state.placedComponents.concat({cellId: cellId, name: compName})
      })
    }

    compOutOfList(cellId) {
      // console.log('Out of list' + cellId.x + cellId.y)
      const placedComponents = this.state.placedComponents.filter( (component) => {
        return component.cellId.x !== cellId.x || component.cellId.y !== cellId.y;
      });

      this.setState({
        placedComponents: placedComponents
      })
    }

    toggleDragging(param) {
      if (param === 'true' ) {
        this.setState({ draggingComp: true});
      } else if (param === 'false' ) {
        this.setState({ draggingComp: false});
      } else {
        this.setState({
          draggingComp: !this.state.draggingComp
        });
      };
    }

    toggleGrid(param) {
      if (param === 'hide' ) {
        this.setState({ showGrid: false});
      } else if (param === 'show' ) {
        this.setState({ showGrid: true});
      } else {
        this.setState({
          showGrid: !this.state.showGrid
        });
      };
    }

    async saveToDatabase(action, email, username=null) {
      const textToEngrave = this.state.engraving ? this.state.engravingText : null;
      const materials = {felt: this.state.felt.replace('felt',''), PLA: this.state.PLA.replace('PLA',''), wood: this.state.wood.replace('wood','')};
      const tableId = await Database.saveTable(this.state.placedComponents, materials, this.state.periquetteType, action, email, username, textToEngrave);
      if (!tableId) return;
      this.setState({
        tableId
      });
      const {configURL, encryptedId} = await Database.generateConfigURL(tableId);
      // window.alert(`Tabla guardada. Link: ${configURL}`);
      return { email, username, configURL, encryptedId, tableId };
    }

    async retrieveTable() {
      let tableId = undefined;

      const URLString = window.location.href.match(/tableId=([^&]*)/);
        
      if(URLString) {
        tableId = await Database.readIdURL(URLString[1]);
      };

      if(tableId) {
        const retrievedTable = await Database.getSavedConfig(tableId);
        // console.log(retrievedTable)
        const tableData = retrievedTable[0][0];
        const componentList = retrievedTable[1];
        const unitNumber = retrievedTable[2][0]['unitNumber']
        if (tableData && componentList) {
          this.setState({
            periquetteType: tableData.Type,
            felt: `felt${tableData.Mat_Felt}`,
            PLA: `PLA${tableData.Mat_PLA}`,
            wood: `wood${tableData.Mat_Wood}`,
            engravingText: tableData.EngravingText,
          });
          // Si el Periquette está comprado podemos poner el número que le toca. Si está solamente guardado mostarmos el 000 por default porque no es un número real ni definitivo
          if (tableData.Action == 'buy') {
            this.setState({
              unitNumber: unitNumber
            });
          }

          componentList.forEach(component => {
            this.compToList({x: component.XCoord, y: component.YCoord}, component.Component);
          });
        }
      };
      return;
    }

    definePqttType() {
      const URLString = window.location.href.match(/type=([^&]*)/);

      if(URLString) {
        const pqttType = URLString[1]
        if(Pqtts[pqttType]) {
          this.setState({
            periquetteType: pqttType
          })
        } else {
          return;
        }
      }
    }

    renderPqttTypeBase(base, t) {
      switch(base) {
        case 'keyHolder' : 
          return (
            <KeyHolderBoard size={Pqtts[this.state.periquetteType].board} currentMaterials={{felt: this.state.felt, PLA: this.state.PLA, wood: this.state.wood}}>
              <GrabadoLogo
                pqttType={this.state.periquetteType}
                engraving={this.state.engraving}
                engravingText={this.state.engravingText}
                unitNumber={this.state.unitNumber}
                toggleMenu={this.toggleStepMenu}
                text = {{
                  engravingPrefix: t.engravingPrefix
                }}
              />
            </KeyHolderBoard>
          );
        default: 
          return (
            <DropTarget className='backgroundDrop' style={{backgroundColor: 'red'}}>
              <Board size={Pqtts[this.state.periquetteType].board} currentMaterials={{felt: this.state.felt, PLA: this.state.PLA, wood: this.state.wood}}>
                <GrabadoLogo 
                  pqttType={this.state.periquetteType}
                  engraving={this.state.engraving}
                  engravingText={this.state.engravingText}
                  unitNumber={this.state.unitNumber}
                  toggleMenu={this.toggleStepMenu}
                  text = {{
                    engravingPrefix: t.engravingPrefix
                  }}
                />
                <Grid
                  pqtt={Pqtts[this.state.periquetteType]} 
                  showGrid={this.state.showGrid}
                  currentMaterials={{felt: this.state.felt, PLA: this.state.PLA, wood: this.state.wood}}
                  placedComponents={this.state.placedComponents}
                  compToList = {this.compToList}
                  compOutOfList = {this.compOutOfList}
                  componentList = {Pqtts[this.state.periquetteType].components}
                  draggingComp={this.state.draggingComp}
                  toggleGrid={this.toggleGrid}
                  toggleDragging={this.toggleDragging}/>
              </Board>
            </DropTarget>
          )
      }      
    }

    componentDidMount() {
      // Add third parties scripts
      AppendScript(require(`../../Util/ChatraWidget.js`));
      AppendScript(require(`../../Util/AnimationsOffOnResize.js`))

      TagManager.initialize({gtmId: 'GTM-TNDW6DN'});
      // ReactPixel.init('1111721305704353');
      // ReactGA.initialize('UA-138680275-1');
      // ReactPixel.pageView();
      // ReactGA.pageview(window.location.pathname + window.location.search);

      // Check if there is a Periquette Type provided and read that type
      this.definePqttType()

      // Check if there is a table id provided and read that table
      this.retrieveTable();
    }

    render() {

      const { t } = this.props; //this is the object that will store all the translations
      const langCode = this.props.i18n.language.split('-')[0];

      return(
        <div>
          <HelpButton/>
          <a href="https://www.Periquette.com" className='logoFly'>
            <img src={require(`./Logos/Isotipo.svg`)} alt='Periquette logo' />
          </a>

          {/* Board */}
          {this.renderPqttTypeBase(
            Pqtts[this.state.periquetteType].base,
            {
            engravingPrefix: t("engravingPrefix")
            }
          )}
          
          <div className='StepButtons'>
            <div className="stepButtonsBg"></div>
            { Pqtts[this.state.periquetteType].components.length > 0 && <StepButton menu='CompMenu' img='compButton.svg' alt='Icono de añadir componentes' onClick={this.toggleStepMenu}>
              {t("componentsMenu")}
            </StepButton> }
            <StepButton menu='ColorMenu' img='compButton.svg' alt='Icono de elegir color' onClick={this.toggleStepMenu}>
              {t("colorsMenu")}
            </StepButton>
            <StepButton menu='DoneMenu' img='doneButton.svg' alta='Icono de confirmar' onClick={this.toggleStepMenu}>
              {t("doneMenu")}
            </StepButton>
          </div>
          <div className='StepMenus'>
            <StepMenu menu='CompMenu' hidden={this.state.CompMenuHidden}  onClick={this.toggleStepMenu}>
              <CompList 
                components={Pqtts[this.state.periquetteType].components}
                currentMaterials = {{
                  felt: this.state.felt, 
                  PLA: this.state.PLA, 
                  wood: this.state.wood
                }} 
                toggleMenu={this.toggleStepMenu}
                toggleGrid={this.toggleGrid} 
                toggleDragging={this.toggleDragging}
                cellSize={Pqtts[this.state.periquetteType].cellSize}
                />
            </StepMenu>
            <StepMenu menu='ColorMenu' hidden={this.state.ColorMenuHidden}  onClick={this.toggleStepMenu}>
              <MaterialList
                currentMaterials = {{
                  felt: this.state.felt, 
                  PLA: this.state.PLA, 
                  wood: this.state.wood
                }} 
                materials={Pqtts[this.state.periquetteType].materials}
                materialChange={this.materialChange}
                text={{
                  chooseOne: t('choosePrompt'),
                  felt: t('feltMaterial'), 
                  PLA: t('plaMaterial'), 
                  wood: t('woodMaterial')
                }}
              />
            </StepMenu>
            <StepMenu menu='DoneMenu' hidden={this.state.DoneMenuHidden}  onClick={this.toggleStepMenu}>
              <div className="menuList">
                <span className={`disabledText ${Pqtts[this.state.periquetteType].components.length > 0 && this.state.placedComponents.length === 0 ? 'show' : ''}`}>{t("disabledButtonsAdvice")}</span>
                <button id="checkoutForm"   disabled={Pqtts[this.state.periquetteType].components.length > 0 && this.state.placedComponents.length === 0} className={`doneButton buyButton`} onClick={() => {this.toggleStepMenu('EngravingPopup'); this.toggleStepMenu('DoneMenu')}}>{t("buyButton")}</button>
                <button id="saveConfigForm" disabled={Pqtts[this.state.periquetteType].components.length > 0 && this.state.placedComponents.length === 0} className={`doneButton invert`} onClick={() => {this.toggleStepMenu('SaveConfigPopup'); this.toggleStepMenu('DoneMenu')}}>{t("saveButton")}</button>
              </div>
              </StepMenu>

            {/* Popups */}
            <Popup menu='EngravingPopup' hidden={this.state.EngravingPopupHidden} onClick={this.toggleStepMenu}>
                <EngravingForm 
                  currentMaterials={{felt: this.state.felt, PLA: this.state.PLA, wood: this.state.wood}}
                  engravingTextChange={this.engravingTextChange}
                  engraving={this.state.engraving}
                  engravingText={this.state.engravingText}
                  toggleState={this.toggleState}
                  toggleStepMenu={this.toggleStepMenu}
                  text={{
                    lang: langCode,
                    title: t("engravingTitle"), 
                    engravingSection: t("engravingSection"),
                    body: t("engravingText"),
                    engravingPrefix: t("engravingPrefix"),
                    placeholder: t("engravingPlaceholder"),
                    engravingButton: t("engravingButton"),
                    free: t("free"),
                    noEngravingButton: t("noEngravingButton"),
                    button: t("doneEngravingButton")
                  }
                }/>
            </Popup>
            <Popup menu='PurchasePopup' hidden={this.state.PurchasePopupHidden} onClick={this.toggleStepMenu}> 
              <Elements stripe={promise}>
                <CheckoutForm 
                  saveToDatabase={this.saveToDatabase} 
                  confirmPurchase={Database.confirmPurchase}
                  toggleStepMenu={this.toggleStepMenu}
                  price={Pqtts[this.state.periquetteType].price} 
                  pqttType={this.state.periquetteType}
                  text={{
                    lang: langCode,
                    title: t("checkoutTitle"),
                    back: t("checkoutBack"),
                    contactDetails: t("checkoutContactDetails"),
                    name: t("checkoutName"),
                    surname: t("checkoutSurname"),
                    phone: t("checkoutPhone"),
                    email: t("checkoutEmail"),
                    shippingDetails: t("checkoutShippingDetails"),
                    address: t("checkoutAddress"),
                    address2: t("checkoutAddress2"),
                    city: t("checkoutCity"),
                    postalCode: t("checkoutPostalCode"),
                    country: t("checkoutCountry"),
                    countrySpain: t("checkoutCountrySpain"),
                    paymentDetails: t("checkoutPaymentDetails"),
                    pay: t("checkoutPay"),
                    price: t("checkoutPrice"),
                    priceInfo: t("checkoutPriceInfo"),
                    couponApplied: t("checkoutCouponApplied"),
                    couponDiscount: t("checkoutCouponDiscount"),
                    couponSubmit: t("checkoutCouponSubmit"),
                    couponError: t("checkoutCouponError"),
                    successTitle: t("checkoutSuccessTitle"),
                    successId: t("checkoutSuccessId"),
                    successThanks: t("checkoutSuccessThanks"),
                    successReception: t("checkoutSuccessReception"),
                    successLink: t("checkoutSuccessLink"),
                    successWorking: t("checkoutSuccessWorking")
                  }}
                />
              </Elements>
            </Popup>
            <Popup menu='SaveConfigPopup' hidden={this.state.SaveConfigPopupHidden} onClick={this.toggleStepMenu} className="savePopup">
              <SaveConfigForm 
                saveToDatabase={this.saveToDatabase} 
                text={{
                  lang: langCode,
                  title: t("saveConfigTitle"), 
                  body: t("saveConfigBody"),
                  placeholder: t("saveConfigPlaceholder"),
                  button: t("saveConfigButton"),
                  successTitle: t("saveConfigSuccessTitle"),
                  successBody: t("saveConfigSuccessBody"),
                  successLink1: t("saveConfigLink1"),
                  successLink2: t("saveConfigLink2"),
                  error: t("saveConfigError")
                }}
              />
            </Popup>
        </div>
      </div>
    );
  }
};

const TranslatedCustomizer = withTranslation()(Customizer);

export default function App() {
    return (
      <Suspense fallback="loading">
        <TranslatedCustomizer />
      </Suspense>
    )
}